// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"

//= require jquery
//= require jquery_ujs
//= require bootstrap

// Navigation
$(document).ready(function() {
  const mobileNav = document.getElementById("mobile-nav-list");

  $('.navbar-toggler').on('click', function(e) {
    if (mobileNav.style.display === "block") {
      mobileNav.style.display = "none";
    } else {
      mobileNav.style.display = "block";
    };
  });

  $(document).mouseup(function(e){
    var menu = $('header');
    if (!menu.is(e.target) && menu.has(e.target).length === 0) {
       $('#mobile-nav-list').slideUp();
    };
  });

  $(window).resize(function() {
    if (document.body.clientWidth > 756 && mobileNav.style.display === "block") {
      $('#mobile-nav-list').slideUp();
    };
  });

  $('.dropdown-toggle').on('mouseover', function() {
    $(this).toggleClass('show');
    $(this).next().toggleClass('show');
  });
  $('.dropdown').on('mouseleave', function() {
    $('.dropdown-menu').removeClass('show');
    $('.dropdown-toggle').removeClass('show');
  });
})